(function ($, window, document, undefined) {
	'use strict';

/* 	// init modr chop plugin
	modr.init('accordion', 'jquery', {
		chop: ['accordion', 'url'],
		helper: ['events']
	}); */

	var VIEW = {
		mobile: 1,
		desktop: 2,
		desktopMinWidth: 768,
		viewMode: ''
	};

	var HEIGHT = {};

	var checkViewMode = function () {
		var changed = false;
		var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

		// set current view mode
		if (windowWidth >= VIEW.desktopMinWidth && VIEW.viewMode !== VIEW.desktop) {
			VIEW.viewMode = VIEW.desktop;
			changed = true;
		} else if (windowWidth < VIEW.desktopMinWidth && VIEW.viewMode !== VIEW.mobile) {
			VIEW.viewMode = VIEW.mobile;
			changed = true;
		}
		return changed;
	};

	var setHeightWithPromoHeader = function () {
		var $header = $('.bnFE .header');
		HEIGHT.headerMeta = $header.find('.header__meta').length > 0 ? ($header.find('.header__meta').css('display') !== 'none') ? $header.find('.header__meta').height() : 0: 0;
		HEIGHT.headerMain = $header.find('.header__main').length > 0 ? ($header.find('.header__main').css('display') !== 'none') ? $header.find('.header__main').height() : 0: 0;
		HEIGHT.headerLogo = $header.find('.header__logo').length > 0 ? ($header.find('.header__logo').css('display') !== 'none') ? $header.find('.header__logo').height() : 0: 0;
		HEIGHT.headerPromo =  $header.find('.header__promo').length > 0  ? ($header.find('.header__promo').css('display') !== 'none') ? $header.find('.header__promo').height() : 0 : 0;
		HEIGHT.headerBrowser = $header.find('.header__browserwarning').length > 0 ? ($header.find('.header__browserwarning').css('display') !== 'none') ? $header.find('.header__browserwarning').height() : 0 : 0;
		HEIGHT.headerError = $header.find('.component.errormessage').length > 0  ? ($header.find('.component.errormessage').css('display') !== 'none') ? $header.find('.component.errormessage').height() : 0 : 0;

		HEIGHT.header = HEIGHT.headerMeta + HEIGHT.headerMain + HEIGHT.headerPromo + HEIGHT.headerBrowser + HEIGHT.headerError;

		$('main').css('padding-top', HEIGHT.header + 'px');
	};


	$(document).ready(function () {

		checkViewMode();
		$(window).on('resize', function () {
			checkViewMode();

			// adjust height for mobile OS bar
			var vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', vh+'px');
		});

		// slim header on scroll
		$(window).on('scroll', function() {
			if ( $(this).scrollTop() > (HEIGHT.headerMeta + HEIGHT.headerLogo) ) {
				$('.bnFE .header').addClass('header--scrolled');
			} else {
				$('.bnFE .header').removeClass('header--scrolled');
			}
		});

		// init responsive image plugin, note: slider images are initialized on slider ready event
		$('.bnFE .picture:not(.picture--slider)').responsiveImage({
			autoDpr: true,
			attributes: ['loading', 'title', 'alt', 'class', 'width', 'height']
		});

		// promotion code cookie
		$('body').promotionCode();

		if (document.querySelector('.navigationCTA')) {
			var navigationCTAItems = document.querySelectorAll('.navigationCTA');
			for (var b = 0; b < navigationCTAItems.length; b++) {
				window.biotope.navigationCTA(navigationCTAItems[b]);
			}
		}

		if (document.querySelector('.bnFE .searchResults__item') !== null) {
			var isIE = global.configuration.get('global.IE');
			var timeout = 1;

			if (isIE) {
				timeout = 500;
			}

			if (location.hash) {
				setTimeout(function () {
					window.scroll({
						top: $('.bnFE .searchResults__item:target .searchResults__title').offset().top - 134,
						behavior: 'smooth'
					});
				}, timeout);
			}
		}

		if ($('.bnFE .header').css('visibility') === 'visible') {
			setHeightWithPromoHeader();
			$(window).on('resize load', function () {
				setHeightWithPromoHeader();
			});
		}

		$('.bnFE .module-content-columns').moduleContentColumns();

		// init stickyToolbar
		$('.bnFE .toolbar').stickyToolbar();

		$('.tariff-widget').tariffWidget();

		// init toolbar selectbox - must be initialized AFTER "stickyToolbar" plugin
		$('.bnFE .selectbox.toolbar__select').selectBox({
			outputSelector: '.toolbar__result',
			template: global.configuration.data.tpl.selectContatactDataOptions
		});

		// init header
		$('.bnFE .header').header();

		// init footer selectbox
		$('.bnFE .selectbox.footer__locationselect')
			.selectBox({
				onDropdownOpen: function () {
					var $dropDown = this.$dropdown;
					var dropdownToBottom = $(document).height() - ($dropDown.offset().top + $dropDown.height());
					if (dropdownToBottom < 0) {
						dropdownToBottom = Math.abs(dropdownToBottom) + 'px';
						$('.offcanvas').css('padding-bottom', dropdownToBottom);
					}
				},
				onDropdownClose: function () {
					$('.offcanvas').css('padding-bottom', '0');
				}
			})
			.on('change', function () {
				window.location.href = this.value;
			});

		/** Execute components BEFORE these lines **/

		// init show more buttons
		$('.bnFE .button--showMore[data-parent]').on('click', function (e) {
			e.preventDefault();

			var $parent = $(this).closest('.' + $(this).data('parent'));
			$parent.removeClass($(this).data('parent'));

			// load responsive images
			$parent.find('.bnFE .picture').each(function () {
				$(this).data('plugin_responsiveImage').loadResponsiveImage();
			});

			// trigger height equalizer
			$parent.trigger('recalculate.height.teaser');

			// remove click event
			$(this).off('click').remove();
		});

		// scroll top
		$('.bnFE [data-scroll-to]').on('click', function (e) {
			e.preventDefault();
			window.scroll({
				top: 0,
				behavior: 'smooth'
			});
		});

		// init login state
		if (typeof $('body').data('customer-signed-in') !== 'undefined' && $('body').data('customer-signed-in') === true) {
			$('.header .header__login').html( '<a href="/logout" title="Logout" rel="nofollow" data-method="delete" class="button button--green button--border icon-logout">Kundenlogout</a>');
		}

		window.counterNumberGlobal = null;

		var callback = function (mutations) {

			var i = 0;

			mutations.forEach(function (mutation) {
				if (mutation.target.className === 'itc') {

					var $itcElem = $(mutation.target);
					if ($itcElem.find('#HTMLID_actionCode').length) {
						var param = $.deparam(window.location.search.replace(/^\?/, ''));
						var acHidden = $.deparam(window.location.search.replace(/^\?/, ''));

						/*jshint camelcase: false */
						param = param.ac_all;
						acHidden = acHidden.ac_hidden;
						/*jshint camelcase: true */

						if (param && param !== '') {
							param = $.removeHtml(param);
							$itcElem.find('#HTMLID_actionCode').attr('value', param).attr('readonly', 'readonly').after('<input type="hidden" name="ac_hidden" value="' + param + '"/><input type="hidden" name="af" value="' + param + '"/>');
							$itcElem.find('#HTMLID_actionCode').siblings('label').hide();

						}

					}
				}
				i++;
			}
			);
		};

		if (typeof MutationObserver === 'function') {
			var mo = new MutationObserver(callback);
			mo.observe(document.documentElement, {
				childList: true,
				attributes: false,
				characterData: false,
				subtree: true,
				attributeOldValue: false,
				characterDataOldValue: false
			});
		}

		if($('.bnFE .form.component').length > 0) {
			// form empty check
			$('.bnFE .form.component .form__element').on('input', function (event) {
				var $element = $(event.target);
				if ($element.val().length > 0) {
					$element.addClass('form__element--notEmpty');
					if ($element.parent().hasClass('selectize-input')) {
						$(this).addClass('form__element--notEmpty');
					}
				} else {
					$element.removeClass('form__element--notEmpty');
					if ($element.parent().hasClass('selectize-input')) {
						$(this).removeClass('form__element--notEmpty');
					}
				}
			});
		}
	});

// $(window).load(function() {});

})(jQuery, window, document);
